
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SALES, ADMIN_USERS_CREATE } from 'Consts/routes';
import { LEAD_SOURCES, LEAD_SOURCE_LABELS, LEAD_MEDIUM_LABELS, LEAD_MEDIUMS } from 'Consts/leads';
import { USER_OFFER_TYPE_DEFAULT, USER_OFFER_TYPE_DIAGNOSTIC } from 'Consts/users';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';
import { getFullName } from 'Utils/user';
import { getTypeLabel as getOfferTypeLabel } from 'Utils/offer';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UsersList from 'Components/admin/users/List';

export const TAB_ALL = 'all';
export const TAB_LIST = 'list';
export const TAB_SURVEYS = 'surveys';
export const TAB_CLIENTS_DIAG = 'clientsDiag';
export const TAB_CLIENTS_ABO = 'clientsAbo';
export const TAB_CLIENTS_UNACTIVE = 'clientsUnactive';
export const TAB_DATA = 'data';

export default class AdminSales extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultFilters = [{
        name: 'search',
        label: 'Szukaj',
        type: 'text',
    }, {
        name: 'hasUserSurvey',
        label: 'Posiada ankietę',
        type: 'select',
        inputProps: {
            options: [{
                label: 'Wszyscy',
                value: '',
            }, {
                label: 'Posiada',
                value: 'true',
            }, {
                label: 'Nie posiada',
                value: 'false',
            }],
        },
    }, {
        name: 'sourceSource',
        label: 'Źródło',
        type: 'select',
        inputProps: {
            options: [
                {
                    label: 'Wszystkie',
                    value: '',
                },
                ...LEAD_SOURCES,
            ],
        },
    }, {
        name: 'sourceMedium',
        label: 'Medium',
        type: 'select',
        inputProps: {
            options: [
                {
                    label: 'Wszystkie',
                    value: '',
                },
                ...LEAD_MEDIUMS,
            ],
        },
    }];

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-sales"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_SALES.path),
                        label: 'Sprzedaż',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Sprzedaż"
                        controls={[{
                            visible: true,
                            key: 'createUser',
                            label: 'Nowy użytkownik',
                            onClick: () => history.push(ADMIN_USERS_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_ALL,
                            label: 'Wszyscy',
                            children: (
                                <UsersList
                                    showDateFilter={true}
                                    location={location}
                                    history={history}
                                    onMapTitle={element => getFullName(element).label}
                                    onMapSubTitle={element => `Numer telefonu: ${element.phone || 'Brak'}`}
                                    predefinedQuery={{
                                        hasUserSurvey: undefined,
                                        sourceSource: undefined,
                                        sourceMedium: undefined,
                                        offerType: undefined,
                                    }}
                                    onMapLabels={element => [{
                                        isVisible: true,
                                        label: 'Ankieta',
                                        state: element.hasUserSurvey
                                            ? 'success'
                                            : 'alert',
                                    }, {
                                        isVisible: true,
                                        label: getOfferTypeLabel({ type: element.offerType }).found 
                                            ? getOfferTypeLabel({ type: element.offerType }).label
                                            : 'Lead',
                                        state: '',
                                    }]}
                                    onMapAdditionals={element => [{
                                        name: 'Źródło',
                                        value: LEAD_SOURCE_LABELS[element.userSource?.source] || element.userSource?.source || 'Brak',
                                    }, {
                                        name: 'Medium',
                                        value: LEAD_MEDIUM_LABELS[element.userSource?.medium] || element.userSource?.medium || 'Brak',
                                    }, {
                                        name: 'Posiada dane UTM',
                                        value: element.hasUserSource ? 'Tak' : 'Nie',
                                    }, {
                                        name: 'Data utworzenia',
                                        value: getFormattedDate(element.createdAt),
                                    }, {
                                        name: 'Data utworzenia ostatniej ankiety',
                                        value: element.lastSurvey?.createdAt
                                            ? getFormattedDate (element.lastSurvey.createdAt)
                                            : 'Brak',
                                    }]}
                                    onMapFilters={filters => [
                                        ...this.defaultFilters,
                                        {
                                            name: 'offerType',
                                            label: 'Typ oferty',
                                            type: 'select',
                                            inputProps: {
                                                options: [{
                                                    label: 'Wszyscy',
                                                    value: '',
                                                }, {
                                                    label: 'Brak',
                                                    value: 'none',
                                                }, {
                                                    label: 'Diagnostyczne',
                                                    value: 'diagnostic',
                                                }, {
                                                    label: 'Abonamentowe',
                                                    value: 'default',
                                                }],
                                            },
                                        }, 
                                    ]}
                                />
                            ),
                        },{
                            key: TAB_LIST,
                            label: 'Leady',
                            children: (
                                <UsersList 
                                    location={location}
                                    history={history}
                                    onMapTitle={element => getFullName(element).label}
                                    onMapSubTitle={element => `Numer telefonu: ${element.phone || 'Brak'}`}
                                    predefinedQuery={{
                                        hasUserSurvey: undefined,
                                        sourceSource: undefined,
                                        sourceMedium: undefined,
                                        offerType: 'none',
                                    }}
                                    onMapLabels={element => [{
                                        isVisible: true,
                                        label: 'Ankieta',
                                        state: element.hasUserSurvey
                                            ? 'success'
                                            : 'alert',
                                    }]}
                                    onMapAdditionals={element => [{
                                        name: 'Źródło',
                                        value: LEAD_SOURCE_LABELS[element.userSource?.source] || element.userSource?.source || 'Brak',
                                    }, {
                                        name: 'Medium',
                                        value: LEAD_MEDIUM_LABELS[element.userSource?.medium] || element.userSource?.medium || 'Brak',
                                    }, {
                                        name: 'Posiada dane UTM',
                                        value: element.hasUserSource ? 'Tak' : 'Nie',
                                    }, {
                                        name: 'Data utworzenia',
                                        value: getFormattedDate(element.createdAt),
                                    }, {
                                        name: 'Data utworzenia ostatniej ankiety',
                                        value: element.lastSurvey?.createdAt
                                            ? getFormattedDate (element.lastSurvey.createdAt)
                                            : 'Brak',
                                    }]}
                                    onMapFilters={filters => this.defaultFilters}
                                />
                            ),
                        }, {
                            key: TAB_CLIENTS_DIAG,
                            label: 'Klienci diagnostyczni',
                            children: (
                                <UsersList 
                                    location={location}
                                    history={history}
                                    onMapTitle={element => getFullName(element).label}
                                    onMapSubTitle={element => `Numer telefonu: ${element.phone || 'Brak'}`}
                                    predefinedQuery={{
                                        hasUserSurvey: undefined,
                                        sourceSource: undefined,
                                        sourceMedium: undefined,
                                        offerType: USER_OFFER_TYPE_DIAGNOSTIC,
                                    }}
                                    onMapLabels={element => [{
                                        isVisible: true,
                                        label: 'Ankieta',
                                        state: element.hasUserSurvey
                                            ? 'success'
                                            : 'alert',
                                    }]}
                                    onMapAdditionals={element => [{
                                        name: 'Źródło',
                                        value: LEAD_SOURCE_LABELS[element.userSource?.source] || element.userSource?.source || 'Brak',
                                    }, {
                                        name: 'Medium',
                                        value: LEAD_MEDIUM_LABELS[element.userSource?.medium] || element.userSource?.medium || 'Brak',
                                    }, {
                                        name: 'Posiada dane UTM',
                                        value: element.hasUserSource ? 'Tak' : 'Nie',
                                    }, {
                                        name: 'Data utworzenia',
                                        value: getFormattedDate(element.createdAt),
                                    }, {
                                        name: 'Data utworzenia ostatniej ankiety',
                                        value: element.lastSurvey?.createdAt
                                            ? getFormattedDate (element.lastSurvey.createdAt)
                                            : 'Brak',
                                    }]}
                                    onMapFilters={filters => this.defaultFilters}
                                />
                            ),
                        }, {
                            key: TAB_CLIENTS_ABO,
                            label: 'Klienci abonamentowi',
                            children: (
                                <UsersList 
                                    location={location}
                                    history={history}
                                    onMapTitle={element => getFullName(element).label}
                                    onMapSubTitle={element => `Numer telefonu: ${element.phone || 'Brak'}`}
                                    predefinedQuery={{
                                        hasUserSurvey: undefined,
                                        sourceSource: undefined,
                                        sourceMedium: undefined,
                                        offerType: USER_OFFER_TYPE_DEFAULT,
                                    }}
                                    onMapLabels={element => [{
                                        isVisible: true,
                                        label: 'Ankieta',
                                        state: element.hasUserSurvey
                                            ? 'success'
                                            : 'alert',
                                    }]}
                                    onMapAdditionals={element => [{
                                        name: 'Źródło',
                                        value: LEAD_SOURCE_LABELS[element.userSource?.source] || element.userSource?.source || 'Brak',
                                    }, {
                                        name: 'Medium',
                                        value: LEAD_MEDIUM_LABELS[element.userSource?.medium] || element.userSource?.medium || 'Brak',
                                    }, {
                                        name: 'Posiada dane UTM',
                                        value: element.hasUserSource ? 'Tak' : 'Nie',
                                    }, {
                                        name: 'Data utworzenia',
                                        value: getFormattedDate(element.createdAt),
                                    }, {
                                        name: 'Data utworzenia ostatniej ankiety',
                                        value: element.lastSurvey?.createdAt
                                            ? getFormattedDate (element.lastSurvey.createdAt)
                                            : 'Brak',
                                    }]}
                                    onMapFilters={filters => this.defaultFilters}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
